<template>
  <sequential-entrance fromBottom>
    <v-container fluid>
      <v-row>
        <v-spacer></v-spacer>
        <v-col class="d-flex" cols="12" sm="1">
          <v-tooltip bottom color="success">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="componentDialog = !componentDialog"
                v-bind="attrs"
                v-on="on"
                right
                fab
                small
                color="success white--text"
                style="margin: 5px"
              >
                <v-icon>mdi-clipboard-plus-outline</v-icon>
              </v-btn>
            </template>
            <span>Add New Component</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12">
          <div
            style="padding: 10px"
            v-for="component in component_list"
            :key="component"
          >
            <v-card
              class="mx-auto"
              link
              to="component_details"
              max-width="85%"
              outlined
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-4">
                    <v-chip
                      v-if="closed"
                      color="pink"
                      outlined
                      small
                      style="text-transform: capitalize"
                      >Closed</v-chip
                    >
                    <v-chip
                      v-else
                      color="success"
                      outlined
                      small
                      style="text-transform: capitalize"
                      >In Progress</v-chip
                    >
                  </div>
                  <v-list-item-title class="text-h6 mb-1">
                    <strong>{{ component.name }}</strong>
                  </v-list-item-title>
                </v-list-item-content>

                <div class="text-overline mb-3">
                  <!-- <strong>General Overview: </strong>
                        <span   style="text-transform:capitalize; color:rgba(216, 82, 69, 0.95)">
                        You haven't Voted Yet
                        </span> -->
                  <v-list subheader two-line>
                    <v-list-item>
                      <v-list-item-avatar size="30">
                        <v-icon class="success lighten-1" dark>
                          mdi-star-circle-outline
                        </v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title>Completion</v-list-item-title>

                        <v-list-item-subtitle> 45%</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>

                <v-alert dense
                  ><v-icon color="success">mdi-check-circle-outline</v-icon>
                  Status
                  <p>No Delay</p>
                </v-alert>

                <v-list-item-avatar tile size="50" color="white"
                  ><v-img src="/vote.png"></v-img>
                </v-list-item-avatar>
              </v-list-item>

              <v-card-actions>
                <v-tooltip bottom color="success">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      fab
                      right
                      top
                      absolute
                      rounded
                      color="success"
                      x-small
                      link
                      to="component_details"
                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>View Component</span>
                </v-tooltip>
              </v-card-actions>
            </v-card>
          </div>
        </v-col>
      </v-row>

      <v-dialog v-model="componentDialog" max-width="60%">
        <v-card>
          <v-card-title><v-icon>mdi-plus</v-icon> New Component</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              v-model="name"
              :error-messages="nameErrors"
              :counter="10"
              label="Name"
              prepend-inner-icon="mdi-text"
              required
            ></v-text-field>

            <v-text-field
              v-model="first"
              label="Descriptions"
              prepend-inner-icon="mdi-text"
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="pink darken-1"
              class="white--text"
              small
              @click="componentDialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="success darken-1"
              small
              class="white--text"
              @click="componentDialog = false"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </sequential-entrance>
</template>

<script>
export default {
  name: "DashBoard",
  data: () => ({
    hovered: true,
    overlay: false,
    componentDialog: false,
    detail: "/components/details/",
    sample: 1,
    component_list: [
      { name: "Digital Ecosystem", id: 1 },
      { name: "Digital Connectivity", id: 2 },
      { name: "Digital Platforms and Services", id: 3 },
    ],
  }),
};
</script>




